window.addEventListener("load", () => {
  const overlay = document.getElementById("overlay");
  const openButtons = document.querySelectorAll(".openModal");
  const closeButtons = document.querySelectorAll(".closeModal");
  let activeModal = null;

  openButtons.forEach(button => {
    button.addEventListener("click", () => {
      activeModal = document.getElementById(button.dataset.modal);
      activeModal.style.display = "block";
      overlay.style.display = "block";
    });
  });

  closeButtons.forEach(button => {
    button.addEventListener("click", () => {
      if (activeModal) {
        activeModal.style.display = "none";
        overlay.style.display = "none";
        activeModal = null;
      }
    });
  });

  overlay.addEventListener("click", () => {
    if (activeModal) {
      activeModal.style.display = "none";
      overlay.style.display = "none";
      activeModal = null;
    }
  });
});

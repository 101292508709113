jQuery(function($){
  $('.map').each(function() {
    var $mapView = $(this).find('.map__view');
    var $mapLocations = $(this).find('.map__location');
    var markers = [];
    var i = 1;

    var map = L.map($mapView.get(0), {
      center: [48.18860, -2.86022],
      zoom: 16,
      minZoom: 12,
      maxZoom: 18,
      scrollWheelZoom: false,
      dragging: !L.Browser.mobile
    });

    L.tileLayer('https://api.maptiler.com/maps/pastel/{z}/{x}/{y}.png?key=3EJ86rXyYK41C947pS90').addTo(map);

    $mapLocations.each(function() {
      var $location = $(this);
      var marker = L.marker([$location.data('lat'), $location.data('long')], {
        icon: L.divIcon({
          iconSize: [60, 60],
          iconAnchor: [30, 30],
        }),
        title: $location.data('title')
      }).addTo(map);
      markers.push(marker);
      i++;
    });

    var refit = function(map, markers) {
      var group = new L.featureGroup(markers);
      map.fitBounds(group.getBounds(), {padding: [10, 10], maxZoom: 16});
    };

    $(window).on('resize', function() {
      refit(map, markers);
    }).trigger('resize');
  });
});

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.copy-btn').forEach(button => {
    button.addEventListener('click', () => {
      var copyText = button.getAttribute('data-copy'); // Correct way to get data attribute
      navigator.clipboard.writeText(copyText)
        .then(() => {
          button.classList.add('copied');
          setTimeout(() => {
            button.classList.remove('copied');
          }, 1000);
        })
        .catch(err => {
          button.classList.add('copied-error');
        });
    });

    const textBak = button.innerHTML;

    button.addEventListener('mouseover', (e) => {
      const copyText = button.getAttribute('data-copy');
      const textNode = document.createTextNode(copyText);
      const span = document.createElement('span');
      span.classList.add('copy-btn__text');
      span.appendChild(textNode);
      button.innerHTML = '';
      button.appendChild(span);
    });

    button.addEventListener('mouseout', (e) => {
      button.innerHTML = textBak;
    });
  });
});
